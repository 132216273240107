.goodBooks{
    padding-top: 10px;
    max-width: 80%;
    border-radius: 5px;
    /* height: 35vw; */
    align-self: center;
    margin: auto;
    background-color:#ceeeeca8;
}

.goodBooks h1{
    padding-bottom: 1vw;
}

.goodBooks img{
    width: 70%;
}

.bookChoice{
    display: inline-block;
    max-width: 28%;
    margin: 1vw;
    vertical-align: top;
}
.bookChoice img{
    /* max-width: 25%; */
}

.bookDescription{
    /* height: 300px; */
}
